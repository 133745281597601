import Vue from "vue";
import moment from "moment-timezone";

Vue.filter('dateFormat',(value)=>{
    return moment(value).format("D MMM, YYYY");
})

Vue.filter('dayMonth',(value)=>{
    return moment(value).format("D MMM");
})

Vue.filter('date', (value) => {
    return moment(value).format('DD/MM/YYYY');
})