import axios from 'axios'

// Initial state set here
const getDefaultState = () => {
    return {
        temp: false,
        user: null,
        profileDocuments: [],
        profileData: []
    }
  }

const ProfilesModule = {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        user(state) {
            return state.user
        },
        profileDocuments(state) {
            return state.profileDocuments
        },
        profileData(state) {
            return state.profileData
        },
        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        }
    },

    mutations: {
        SET_BOOL(state, data) {
            state.temp = data;
        }, 
        SET_USER(state, data) {
            state.user = data
        },
        SET_PROFILE_DOCUMENTS(state, data){
            state.profileDocuments = data
        },
        SET_PROFILE_DATA(state, data){
            state.profileData = data
        },
    },

    actions: {
        storeProfile({ commit }, data, config) {
            return axios
                .post('/account/profile', data, config)
                .then((res) => {                                              
                    commit('SET_USER', res.data);     
                    return res;                  
                })
        },
        sendPasscodeUsername(context, data) {
            return axios
                .post('/profile/username/passcode-send', data)
                .then((res) => {                                              
                    return res;                  
                })
        },
        updateUsername(context, data) {
            return axios
                .post('/profile/username/save', data)
                .then((res) => {                                              
                    return res;                  
                })
        },
        resendPasscode(context, data) {
            return axios
                .post('/profile/resend-passcode', data)
                .then((res) => {                                              
                    return res;                  
                })
        },
        resendPasscodePassword() {
            return axios
                .get('/profile/password/resend-passcode')
                .then((res) => {                                              
                    return res;                  
                })
        },
        sendPasscodePassword(context, data) {
            return axios
                .post('/profile/password/passcode-send', data)
                .then((res) => {                                              
                    return res;                  
                })
        },
        updatePassword(context, data) {
            return axios
                .post('/profile/password/save', data)
                .then((res) => {                                              
                    return res;                  
                })
        },
        storeDocuments({ commit }, formData, config) {
            return axios
                .post('/profile/upload-documents', formData, config)
                .then((res) => {                                              
                    commit('SET_USER', res.data);     
                    return res;                  
                })
        },
        getProfileDocuments({commit}) {
            return axios
            .get('/profile/get-documents')
            .then((res) => {     
                //console.log("aa",res);                                         
                commit('SET_PROFILE_DOCUMENTS', res.data.data);        
                return res;          
            })
        },
        getProfileData({commit}) {
            return axios
            .get('/account/profile')
            .then((res) => {     
                //console.log("aa",res);                                         
                commit('SET_PROFILE_DATA', res.data.data);   
                return res;               
            })
        },
        deleteDocument(context, id) {
            return axios
                .delete(`/profile/${id}/document`)
                .then((res) => {                                              
                    return res;
                })
        }, 
        downloadDocument(context, id) {
            return axios
                .get(`/profile/document/${id}/download`, {responseType: 'arraybuffer'})
                .then((res) => {                                              
                    return res;
                })
        }, 
        
    }
}

export default ProfilesModule
