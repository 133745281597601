// Initial state set here
const getDefaultState = () => {
  return {
    text: "",
    color: "",
    timeout: "",
    showOverlayLoader: false
  }
}

const SnackbarModule = {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        showOverlayLoader(state) {
            return state.showOverlayLoader
        }
    },
    mutations: {
      SHOW_MESSAGE(state, payload) {
        state.text = payload.text;
        state.color = payload.color;
        state.timeout = payload.timeout;
      },
      UPDATE_OVERLAY(state, data) {
        state.showOverlayLoader = data?true:false;        
      },
      RESET_STATE (state) {
        Object.assign(state, getDefaultState())
      }
    },
    actions: {
      showSnack({ commit }, payload) {
        commit("SHOW_MESSAGE", payload);
      },
      showOverlay({ commit }, data) {
          commit('UPDATE_OVERLAY', data);
      }
    },
  };
 

export default SnackbarModule
