import axios from 'axios'

// Initial state set here
const getDefaultState = () => {
    return {
        notifications: [],
        alerts: [],
        searchedProjects: [],
        alertCount:0,
        notificationCount: 0
    }
}

const NotificationModule = {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        notifications(state) {
            return state.notifications
        },
        alerts(state) {
            return state.alerts
        },
        searchedProjects(state) {
            return state.searchedProjects
        },
        
        alertCount: (state) => state.alertCount,

        notificationCount: (state) => state.notificationCount 
    },

    mutations: {
        SET_NOTIFICATIONS(state, data) {
            state.notifications = data
        },
        SET_ALERTS(state, data) {
            state.alerts = data
        },
        SET_SEARCHED_PROJECTS(state, data) {
            state.searchedProjects = data
        },
        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        },
        SET_ALERT_COUNT(state, count) {
            state.alertCount = count
        },
        SET_NOTIFICATION_COUNT(state, count) {
            state.notificationCount = count
        },
        UPDATE_NOTIFICATION(state, data) {
            var index = state.notifications.findIndex((item) => {
                return item.id === data.id;
              });
            state.notifications[index] = data;
            state.notificationCount -= 1;
        },
        UPDATE_ALERT(state, data) {
            var index = state.alerts.findIndex((item) => {
                return item.id === data.id;
              });
            state.alerts[index] = data;
            state.alertCount -= 1;
        }
    },

    actions: {
        getNotifications({ commit }) {
            return axios.
                get('notifications')
                .then((res) => {                    
                    commit('SET_NOTIFICATIONS', res.data.data);
                })
        },
        getSearchedData({ commit }, title) {
            return axios.
                get(`search?q=${title}`)
                .then((res) => {                    
                    commit('SET_SEARCHED_PROJECTS', res.data.data);
                    return res;
                })
        },
        getAlerts({ commit }) {
            return axios.
                get('/notifications/alerts')
                .then((res) => {                    
                    commit('SET_ALERTS', res.data.data);
                })
        },
        markAsRead({commit}, {notification, notificationType}) {
            notificationType = notificationType?notificationType:'';
            return axios
                .get(`/notifications/mark-as-read/${notification.id}`)
                .then((res) => {   
                    if(notificationType === 'alert') {
                        commit('UPDATE_ALERT', res.data.data);
                    } else {
                        commit('UPDATE_NOTIFICATION', res.data.data);
                    }                                           
                    return res;
                })
        }, 
        markAllAsRead({commit}, notificationType) {
            notificationType = notificationType?notificationType:'';
            return axios
                .get(`notifications/mark-all-as-read?type=${notificationType}`)
                .then((res) => {
                    if(notificationType === 'alert') {
                        commit('SET_ALERT_COUNT', 0);
                    } else {
                        commit('SET_NOTIFICATION_COUNT', 0);
                    }                                              
                    return res;
                })
        }, 
        countNotification({commit}, notificationType) {
            notificationType = notificationType?notificationType:'';
            return axios.
                get(`/notifications/count?type=${notificationType}`)
                .then((res) => {     
                    if(notificationType === 'alert') {
                        commit('SET_ALERT_COUNT', res.data.count);
                    } else {
                        commit('SET_NOTIFICATION_COUNT', res.data.count);
                    }            
                    return res; 
                })
        },
    }
}

export default NotificationModule
