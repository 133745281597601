import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

const MainLayout = () => import('@/layouts/MainLayout')

// Alert
const Alert = () => import('@/views/Alert/Index');

// Notification
const Notification = () => import('@/views/Notification/Index');

// Profile
const Profile = () => import('@/views/profile/Index');

// Account Status
const AccountStatus = () => import('@/views/AccountStatus/Index');

// Pricing Plan
const Pricing = () => import('@/views/Pricing/Index');
//Search

const SearchResult = () => import('@/views/search/search');

// Personal
const PersonalDashboard = () => import('@/views/longleap/project/Index');

// Teams
const CreateNewTeam = () => import('@/views/Team/Create');
const EditTeam = () => import('@/views/Team/Edit');
const NewTeamProjects = () => import('@/views/longleap/project/Index');

// Dashboard
const MainDashboard = () => import('@/views/Dashboard');

// Settings
const Settings = () => import('@/views/Settings/Index');

// Auth
const Signin = () => import('@/views/auth/Signin');
const Signup = () => import('@/views/auth/Signup');
const ForgotEmail = () => import('@/views/auth/ForgotEmail');
const ForgotPassword = () => import('@/views/auth/ForgotPassword');

//Terms and Conditions
import Terms from "@/components/terms/Terms";
import PasswordReset from "./views/auth/PasswordReset";
import axios from "axios";

Vue.use(VueRouter)

const Router = new VueRouter({
    mode: 'history',
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    routes: [

        {
            path: '',
            component: MainLayout,
            meta: {auth: true},
            children: [
                {path: '/', redirect: '/main-dashboard'},
                {path: '/main-dashboard', component: MainDashboard, name: 'MainDashboard'},
                {path: '/notification', component: Notification, name: 'Notification'},
                {path: '/alert', component: Alert, name: 'Alert'},
                {
                    path: '/personal-project/dashboard',
                    component: PersonalDashboard,
                    name: 'PersonalProjectDashboard'
                },
                {path: '/team/create', component: CreateNewTeam, name: 'NewTeam'},
                {path: '/team/:id/edit', component: EditTeam, name: 'EditTeam'},
                {path: '/team/projects', component: NewTeamProjects, name: 'TeamProject'},
                {path: '/account-status', component: AccountStatus},
                {path: '/profile', component: Profile, name: 'Profile'},
                {path: '/pricing', component: Pricing, name: 'Pricing'},
                {path: '/settings', component: Settings},
                {path: '/searchResult', component: SearchResult, name: 'SearchResult'},
            ],
        },

        {path: '/signin', component: Signin},
        {path: '/signup', component: Signup},
        {path: '/forgot-password', component: ForgotPassword},
        {path: '/forgot-email', component: ForgotEmail},
        {path: '/terms', component: Terms, name: 'terms'},
        {path: '/password-reset/:id', component: PasswordReset, props: true}
    ]
});

Router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth) && !store.getters['auth/isLoggedIn']) {
        next('/signin')
        return
    }

    const myPromise = new Promise((resolve, reject) => {
        let isCreateMenu = (to.query.menu === 'createPublicProject' || to.query.menu === 'createPrivateProject') && (!to.query.hasOwnProperty('project_id'));
        if (to.name === "PersonalProjectDashboard" && isCreateMenu) {
            axios.get('/account-status')
                .then(response => {
                    response = response.data;
                    if (response.success) {
                        if (response.data.projects.limitReached) {
                            response = response.data;
                            store.state.show_error = response.projects.message;
                            resolve(from);
                        }
                        resolve();
                    } else {
                        store.state.show_error = to.query.menu === 'createPublicProject'
                            ? "Please select a pricing plan before creating a Personal Public Project" :
                            "Please select a pricing plan before creating a Personal Private Project";
                        resolve(from);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        } else if (to.name === "TeamProject" && isCreateMenu) {
            axios.get(`/account-status/${to.query.id}/team`)
                .then(response => {
                    response = response.data;
                    if (response.success) {
                        if (response.data.projects.limitReached) {
                            response = response.data;
                            store.state.show_error = response.projects.message;
                            resolve(from);
                        }
                        resolve();
                    } else {
                        store.state.show_error = to.query.menu === 'createPublicProject'
                            ? "Please select a pricing plan before creating a Team Public Project" :
                            "Please select a pricing plan before creating a Team Private Project";
                    }
                    resolve(from);
                })
                .catch((error) => {
                    reject(error);
                });
        } else {
            resolve();
        }
    });

    myPromise.then((route) => {
        if (route) {
            next({path: route.fullPath, query: route.query});
        } else {
            next();
        }
    })
});

const originalPush = Router.push;
Router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            return err
        }

        return Promise.reject(err)
    })
}

export default Router;
