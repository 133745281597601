import axios from 'axios';
import store from './store'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.interceptors.request.use(async function (config) {
    if (!config.url.includes('resend-password') && !config.url.includes('change-password') && !config.url.includes('validate-password') && !config.url.includes('send-passcode') && !config.url.includes('forgot-password') && !config.url.includes('signup') && !config.url.includes('signin') && !config.url.includes('refresh') && !config.url.includes('reset-password')) {
        await getAuthToken();
    }
    const token = store.getters['auth/getToken'];
    if (token && token != 'null') {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        store.dispatch('auth/clearToken')
        window.location = '/signin';
    } else {
        return Promise.reject(error);
    }
});

async function getAuthToken() {
    // if the current store token expires soon
    // 1800000 = means 30 mins
    if (store.getters['auth/tokenExpiresIn'] - 1800000 <= Date.now()) {
        try {
            await store.dispatch('auth/refresh');
        } catch (error) {
            return Promise.reject(error);
        }

    }
    return;
}
