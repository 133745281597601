import axios from 'axios'

// Initial state set here
const getDefaultState = () => {
    return {
        temp: false,
        documentCreated: false,
        documentAssigned: false,
        project: {collaborators:[]},
        documents: [],
        collaborators: [],
        details: [],
        users: []
    }
  }

const PrivateProjectModule = {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        project(state) {
            return state.project
        },
        details(state) {
            return state.details
        },
        documents(state) {            
            // return state.documents.map((item)=> {
            //     item.collaborators_ids = [];
            //     return item;
            // });
            // return state.documents;

            let posArr = [];
            let pos = 0;
            return state.documents.map((item)=> {
                if(posArr.includes(item.position)) {
                    item.position = pos;    
                }
                posArr.push(item.position);
                pos++;
                return item;
            });
        },
        collaborators(state) {
            let posArr = [];
            let pos = 0;
            return state.collaborators.map((item)=> {
                if(posArr.includes(item.position)) {
                    item.position = pos;    
                }
                posArr.push(item.position);
                pos++;
                return item;
            });
            // return state.collaborators;
        }
    },

    mutations: {
        SET_BOOL(state, data) {
            state.temp = data;
        }, 
        SET_PROJECT(state, data) {
            state.project = data
        }, 
        SET_DETAILS(state, data) {
            state.details = data
        }, 
        SET_DOCUMENTS(state, data) {
            state.documents = data
        }, 
        SET_COLLABORATORS(state, data) {
            state.collaborators = data
        }, 
        SET_USERS(state, data) {
            state.users = data
        }, 
        DOCUMENTS_CREATED(state, data) {
            state.documentCreated = data
        }, 
        DOCUMENTS_ASSIGNED(state, data) {
            state.documentAssigned = data
        }, 
        UPDATE_PROJECT_COLLABORATOR(state, {data, collaboratorId}) {                       
            state.project.collaborators.map((item)=> {
                if(item.id === collaboratorId) {
                    item.documents = data;
                    item.expanded = true;
                } else {
                    item.expanded = false;    
                    item.documents = [];
                }
                return item;
            })            
        },
        COLLAPSE_COLLABORATOR_DOCUMENT(state, collaboratorId) {                       
            state.project.collaborators.map((item)=> {
                if(item.id === collaboratorId) {                    
                    item.expanded = false;
                    item.documents = [];
                } 
                return item;
            })            
        },
        REMOVE_COLLABORATOR(state, collaboratorId) {
            const i = state.collaborators.map(item => item.id).indexOf(collaboratorId);
            state.collaborators.splice(i, 1);    
        },    
        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        }    
    },

    actions: {
        storePrimaryInfo({ commit }, data, config) {
            return axios
                .post('/projects/private', data, config)
                .then((res) => {                                              
                    commit('SET_PROJECT', res.data.data);     
                    return res;                  
                })
        },
        updatePrimaryInfo({ commit }, {formData, projectId}, config) {
            return axios
                .post(`/projects/${projectId}/update`, formData, config)
                .then((res) => {                                              
                    commit('SET_PROJECT', res.data.data);     
                    return res;                  
                })
        },
        getPrimaryInfo({ commit }, projectId) {
            return axios
                .get(`/projects/${projectId}/primary-info`)
                .then((res) => {                                              
                    commit('SET_PROJECT', res.data.data);     
                    return res;                  
                })
        },
        storeDeadlines({ commit }, data) {
            return axios
                .put(`/projects/${data.id}/set-deadline`, data.payload)
                .then((res) => {                                                  
                    commit('SET_PROJECT', res.data.data);    
                    return res;                   
                })
        },
        storeCollaborators({ commit }, {collaborators, projectId}) {
            return axios
                .post(`/projects/${projectId}/collaborators`, {collaborators:collaborators})
                .then((res) => {                                                  
                    commit('SET_PROJECT', res.data.data);    
                    return res;                   
                })
        },
        importCollaborators(context, formData, config) {           
            return axios
                .post(`/projects/upload-csv`, formData, config)
                .then((res) => {        
                    return res;                   
                })
        },
        storeSupportStaffs({ commit }, {supportStaffs, projectId}) {           
            return axios
                .post(`/projects/${projectId}/support-staffs`, {supportStaffs:supportStaffs})
                .then((res) => {                                                  
                    commit('SET_PROJECT', res.data.data);    
                    return res;                   
                })
        },
        storeDocuments({ commit }, {formData, projectId}, config) {           
            return axios
                .post(`/projects/${projectId}/documents`, formData, config)
                .then((res) => {                                                  
                    commit('DOCUMENTS_CREATED', true);    
                    return res;                   
                })
        },
        submitDocuments(context, {formData, projectId}, config) {           
            return axios
                .post(`/projects/${projectId}/upload-documents`, formData, config)
                .then((res) => {             
                    return res;                   
                })
        },
        downloadDocuments(context, {data, projectId, userId}) {           
            return axios
                .post(`/projects/${projectId}/users/${userId}/download-documents`, data, {responseType:'arraybuffer'})
                .then((res) => {             
                    return res;                   
                })
        },
        saveDecision(context, {data, projectId}) {           
            return axios
                .post(`/projects/${projectId}/save-decision`, data)
                .then((res) => {             
                    return res;                   
                })
        },
        assignDocuments({ commit }, {data, projectId}) {           
            return axios
                .post(`/projects/${projectId}/assign-collaborators`, {data:data})
                .then((res) => {                                                  
                    commit('DOCUMENTS_ASSIGNED', true);    
                    return res;                   
                })
        },
        getDocuments({ commit }, projectId) {           
            return axios
                .get(`/projects/${projectId}/documents`)
                .then((res) => {                                                  
                    commit('SET_DOCUMENTS', res.data.data);    
                    return res;                   
                })
        },
        getDocumentsByCollaborator({ commit }, {projectId, collaboratorId}) {           
            return axios
                .get(`/projects/${projectId}/collaborators/${collaboratorId}/documents`)
                .then((res) => {                                                  
                    commit('UPDATE_PROJECT_COLLABORATOR', {data: res.data.data, collaboratorId:collaboratorId});    
                    return res;                   
                })
        },
        async getCollaborators({ commit }, projectId) {           
            return await axios
                .get(`/projects/${projectId}/collaborators`)
                .then((res) => {                                                  
                    commit('SET_COLLABORATORS', res.data.data);    
                    return res;                   
                })
        },             
        stopProject({ commit }, id) {
            return axios
                .post(`/projects/${id}/stop`)
                .then((res) => {                                              
                    commit('SET_BOOL', res.data.data);     
                    return res;
                })
        }, 
        deleteAttachment({ commit }, data) {
            return axios
                .delete(`/projects/${data.project_id}/attachments/${data.attachment_id}`)
                .then((res) => {                                              
                    commit('SET_BOOL', res.data.data);     
                    return res;
                })
        },                  
        startProject(context, projectId) {           
            return axios
                .post(`/projects/${projectId}/start-project`, {})
                .then((res) => {                    
                    return res;                   
                })
        },
        getProjectDetail({ commit }, projectId) {           
            return axios
                .get(`/projects/${projectId}`)
                .then((res) => {                                                  
                    commit('SET_PROJECT', res.data.data);    
                    return res;                   
                })
        },  
        getProjectDetailDocUpload({ commit }, projectId) {           
            return axios
                .get(`/projects/${projectId}/collaborator-detail`)
                .then((res) => {                                                  
                    commit('SET_PROJECT', res.data.data);    
                    return res;                   
                })
        },  
        removeUser({ commit }, {projectId, userId, userType}) {
            return axios
                .delete(`/projects/${projectId}/users/${userId}?user_type=${userType}`)
                .then((res) => {  
                    if(userType === 'COLLABORATOR') {
                        commit(`REMOVE_${userType}`, userId);
                    }           
                    return res;
                })
        },
        removeDocument(context, {projectId, documentId}) {
            return axios
                .delete(`/projects/${projectId}/documents/${documentId}`)
                .then((res) => {                          
                    return res;
                })
        },     
        removeDocumentTemplate(context, {projectId, documentId}) {
            return axios
                .delete(`/projects/${projectId}/documents/${documentId}/template`)
                .then((res) => {                          
                    return res;
                })
        },    
        getSupportStaffs(context, projectId) {           
            return axios
                .get(`/projects/${projectId}/support-staffs`)
                .then((res) => {                        
                    return res;                   
                })
        },   
        verifyPid({ commit }, {pid}) {           
            return axios
                .post(`/projects/verify-pid`, {pid:pid})
                .then((res) => {                                                  
                    commit('SET_BOOL', res.data.data);        
                    return res;                   
                })
        },
        getProjectDetailsByPid({commit}, pid) {           
            return axios
                .get(`/projects/${pid}/project-details`)
                .then((res) => {     
                    commit('SET_DETAILS', res.data.data);     
                    return res;                   
                })
        },  
        addProject({ commit }, {id}) {           
            return axios
                .post(`/projects/${id}/add-project`)
                .then((res) => {                                                  
                    commit('SET_BOOL', res.data.data);        
                    return res;                   
                })
        },
        sendEmailDecisions(context, {projectId, collaboratorId}) {
            return axios
                .post(`/projects/${projectId}/collaborators/${collaboratorId}/send-decisions`)
                .then((res) => {                          
                    return res;
                })
        },
        searchUsers(context, {email}) {
            return axios
                .post(`/search-user`,{email: email})
                .then((res) => {
                    return res;
                })
        }
    }
}

export default PrivateProjectModule
