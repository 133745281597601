import axios from 'axios'

// Initial state set here
const getDefaultState = () => {
    return {
        temp: false,
        team: {members:[]},
        all_teams: []
    }
  }

const TeamModule = {
    namespaced: true,

    state: getDefaultState(),

    getters: {
        team(state) {
            return state.team
        },
        all_teams(state) {
            return state.all_teams
        }
    },

    mutations: {
        SET_BOOL(state, data) {
            state.temp = data;
        }, 
        SET_TEAM(state, data) {
            state.team = data
        },    
        SET_ALL_TEAMS(state, data) {
            state.all_teams = data
        },
        RESET_STATE (state) {
            Object.assign(state, getDefaultState())
        }
    },

    actions: {
        storeTeam({ commit }, data, config) {
            return axios
                .post('/teams', data, config)
                .then((res) => {                                              
                    commit('SET_TEAM', res.data.data);     
                    return res;                  
                })
        },
        upgradeTeamPlan({ commit }, data) {
            return axios
                .post('/upgrade-team-plans', data)
                .then((res) => {                                              
                    commit('SET_TEAM', res.data.data);     
                    return res;                  
                })
        },
        getTeamById({ commit }, id) {
            return axios
                .get(`/teams/${id}`)
                .then((res) => {  
                    console.log(res.data.data);                                            
                    commit('SET_TEAM', res.data.data);     
                    return res;                  
                })
        },
        getTeams({ commit }) {
            return axios.
                get('/teams')
                .then((res) => {                    
                    commit('SET_ALL_TEAMS', res.data.data);
                })
        },
        deleteMember({commit}, data) {
            return axios
                .delete(`/teams/${data.teamId}/members/${data.userId}`)
                .then((res) => {                                              
                    commit('SET_BOOL', true);     
                    return res;
                })
        },  
    }
}

export default TeamModule
