import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css';
import './assets/template/css/light.css';
// import './assets/template/js/app.js';
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import '@fortawesome/fontawesome-free/js/all.js'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

require('./filters');


Vue.config.productionTip = false

locale.use(lang)
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
