<template>
    <router-view></router-view>
  <!--    <v-app>-->
  <!--        <v-overlay :value="showOverlayLoader">-->
  <!--            <v-progress-circular-->
  <!--                indeterminate-->
  <!--                size="58"-->
  <!--                color="primary"-->
  <!--            ></v-progress-circular>-->
  <!--        </v-overlay>-->
  <!--        <router-view></router-view>-->
  <!--    </v-app>-->
</template>

<script>
import {mapGetters} from "vuex";
import {Notification} from "element-ui";

export default {
    name: "App",
    components: {},
    computed: {
        errorAlert() {
            return this.$store.state.show_error
        },
        ...mapGetters("snackbar", ["showOverlayLoader"]),
    },
    watch: {
        errorAlert: {
            handler: function (val) {
                if (val) {
                    Notification.error(this.$store.state.show_error)
                    this.$store.state.show_error = false
                }
            }
        },
    },
};
</script>

<style scoped>
.v-application {
    font-family: "Poppins", sans-serif;
}
</style>
