import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import AuthModule from './modules/auth.js'
import StripeModule from './modules/stripes.js'
import PrivateProjectModule from './modules/privateProject.js'
import PublicProjectModule from './modules/publicProject.js'
import ProjectListModule from './modules/projectLists.js'
import SnackbarModule from './modules/snackbar.js'
import TeamModule from './modules/teams.js'
import TeamPrivateProjectModule from './modules/teamPrivateProject.js'
import TeamPublicProjectModule from './modules/teamPublicProject.js'
import TeamProjectListModule from './modules/teamProjectLists.js'
import NotificationModule from './modules/notifications.js'
import PricingPlansModule from './modules/pricingPlans.js'
import ProfilesModule from './modules/profiles.js'
import SubscriptionModule from './modules/subscription'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth: AuthModule,
        stripes: StripeModule,
        privateProjects: PrivateProjectModule,
        publicProjects: PublicProjectModule,
        projectLists: ProjectListModule,
        snackbar: SnackbarModule,
        teams: TeamModule,
        teamPrivateProjects: TeamPrivateProjectModule,
        teamPublicProjects: TeamPublicProjectModule,
        teamProjectLists: TeamProjectListModule,
        notifications: NotificationModule,
        pricingPlans: PricingPlansModule,
        profiles: ProfilesModule,
        subscription: SubscriptionModule
    },
    plugins: [
        createPersistedState(),
    ],
    state: {
        show_error: false
    },
    getters: {
      redirectToProjectNextRoute: (state) => (route, id) =>{
          let components = [
              'primary_info',
              'upload_project_docs',
              'project_deadline',
              'add_collaborators',
              'list_required_docs',
              'assign_documents',
              'add_support_members',
              'project_summary'
          ]
          console.log('route');
          console.log(route);
          let currentComponent = route.query.active;
          let indexOfCurrent, indexOfNext = components.indexOf(currentComponent);
          if(currentComponent){
              if(currentComponent && currentComponent === 'list_required_docs' && route.query.menu.includes('Public')){
                  indexOfNext = indexOfCurrent += 2;
              }else{
                  indexOfNext += 1;
              }
              route.query.active = components[indexOfNext];
              route.query.project_id = id;
              return route;
          }
      }
    }
})